import React from 'react'
import NextLink from 'next/link'

interface Props {
  children: Array<JSX.Element | string | null> | JSX.Element | string | null
  className?: string
  href: string
  target?: string
  rel?: string
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const Link: React.FC<Props> = ({ children, href, className, rel, onClick, target }) => {
  function handleClick(e) {
    e.preventDefault()
    const whatTarget = target ? target : '_self'
    if (e.type === 'click' || e.key === 'Enter') {
      onClick?.(e)
      window.open(href, whatTarget)
    }
  }

  return (
    <NextLink href={href} className={className} rel={rel} onClick={handleClick}>
      {children}
    </NextLink>
  )
}

export default Link
