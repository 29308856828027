import React, { useRef, useEffect } from 'react'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

interface Props {
  className?: string
  label: string
  type?: 'text' | 'email' | 'password' | 'tel'
  value?: string
  textarea?: boolean
  onChange: (value: string) => void
}

const autoExpand = ({ target }) => {
  target.style.height = 'inherit'
  target.style.height = `${target.scrollHeight + 6}px`
}

interface Event {
  target: { value: string }
}

const Input: React.FC<Props> = ({ label, value, onChange, className, type = 'text', textarea }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const handleChange = (event: Event) => {
    onChange(event.target.value)
  }

  useEffect(() => {
    if (inputRef.current && textarea) {
      autoExpand({ target: inputRef.current })
    }
  })

  return (
    <label {...bem('', '', className)}>
      {textarea ? (
        <textarea
          {...bem('field', { textarea: true, value })}
          rows={1}
          value={value}
          onChange={handleChange}
          onInput={autoExpand}
          ref={inputRef}
        />
      ) : (
        <input {...bem('field', { value })} type={type} value={value} onChange={handleChange} />
      )}
      <span {...bem('label')}>{label}</span>
    </label>
  )
}

export default Input
