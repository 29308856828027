import { Blocks } from '../types/blocks'

const defaults = { nonTextBehavior: 'remove' }

export default function portableToPlain(blocks: Blocks, opts = {}): string {
  if (!blocks) {
    return ''
  }

  const options = Object.assign({}, defaults, opts)
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
      }

      return block.children.map((child) => child.text).join('')
    })
    .join('\n\n')
}

export function getTextFromBlock(children: any): string {
  return children
    .map((item: any) => {
      if (item?.props?.node?.children) {
        return item?.props?.node?.children
      }

      return item
    })
    .join('\n\n')
}

export function clearEmptyBlocks(blocks: Blocks): Blocks {
  return (
    blocks?.filter(
      ({ _type, children }) => _type !== 'block' || (children && children.some(({ text }) => text)),
    ) || []
  )
}
