import { website, organization } from '../../helpers/schemaOrg'
import Head from 'next/head'

const schema = JSON.stringify([website(), organization()])

const BaseSchemaOrg: React.FC = () => {
  return (
    <Head>
      <script
        key="base-schema-org"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: schema,
        }}
      />
    </Head>
  )
}

export default BaseSchemaOrg
