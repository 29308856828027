import React, { useRef, useEffect } from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import useAccordion from '../../hooks/useAccordion'
import Icon from '../Icon'

const bem = BEMHelper(styles)

export interface Props {
  title: string
  large?: boolean
  center?: boolean
  expanded?: boolean
  defaultExpanded?: boolean
  children: React.ReactNode
}

const Accordion: React.FC<Props> = ({
  title,
  defaultExpanded = false,
  expanded: forceExpanded,
  large,
  center,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { height, expanded, doneAnimating, toggle, expand, collapse, handleTransitionEnd } =
    useAccordion(wrapperRef, forceExpanded || defaultExpanded)

  useEffect(() => {
    if (forceExpanded) {
      expand()
    } else if (forceExpanded === false) {
      collapse()
    }
  }, [forceExpanded, collapse, expand])

  return (
    <aside {...bem('', { expanded, collapsed: !expanded, large, center })}>
      <button type="button" {...bem('toggle')} onClick={toggle} aria-expanded={expanded}>
        <h2 {...bem('title')}>{title}</h2>
        <span {...bem('indicator')}>
          <Icon icon="chevron" direction="down" />
        </span>
      </button>

      <div
        {...bem('wrapper', { expanded: doneAnimating })}
        ref={wrapperRef}
        style={expanded ? { maxHeight: height } : { maxHeight: '0px' }}
        onTransitionEnd={handleTransitionEnd}
        tabIndex={-1}
      >
        <div {...bem('content')}>{children}</div>
      </div>
    </aside>
  )
}

export default Accordion
