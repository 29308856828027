import React from 'react'
import { useRouter } from 'next/router'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

import usePageAnimation from '../../hooks/usePageAnimation'
import Header from '../Header'
import Footer from '../Footer'
import VippsTips from '../VippsTips'
import BaseSchemaOrg from '../BaseSchemaOrg'

const bem = BEMHelper(styles)

interface Props {
  children: JSX.Element[] | JSX.Element | string
}

const PAGE_TYPES = {
  '/sok': 'search',
}

const Layout: React.FC<Props> = ({ children }) => {
  usePageAnimation()

  const router = useRouter()
  const pageType = Object.keys(PAGE_TYPES).find((slug) => router.asPath.startsWith(slug))
  const type = pageType ? PAGE_TYPES[pageType] : undefined

  return (
    <div {...bem('', type)}>
      <Header />
      <BaseSchemaOrg />
      <main {...bem('content')}>
        {children}
        <VippsTips />
      </main>

      <Footer />
    </div>
  )
}

export default Layout
