/* eslint-disable react/jsx-key */
import React from 'react'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

export const ICONS = {
  check: [[14, 11], <path d="M0.758301 6.5L4.34862 10L13.5809 1" {...bem('line')} />],
  chevron: [[7, 11], <path d="M0.5 10.5L5.5 5.5L0.5 0.5" {...bem('line')} />],
  close: [[16, 16], <path d="M15 1L1 15M15 15L1 1" {...bem('line')} />],
  remove: [[11, 11], <path d="M10.5 1L1 10.5M10.5 10.5L1 1" {...bem('line')} />],
  plus: [[14, 14], <path d="M13.435 6.7175H0M6.71777 13.435L6.71777 0" {...bem('line')} />],
  play: [
    [14, 16],
    <path d="M14 7.9998L0.5 15.794L0.500001 0.205566L14 7.9998Z" {...bem('fill')} />,
  ],
}

interface Props {
  icon: string
  direction?: 'left' | 'up' | 'down' | 'right'
  className?: string
  title?: string
}

const Icon: React.FC<Props> = ({ icon, direction, className, title, ...props }) => {
  if (!icon || !ICONS[icon]) {
    console.warn('Unsupporded icon', icon)
    return null
  }

  const [[width, height], content] = ICONS[icon]

  return (
    <svg
      {...props}
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      {...bem('', [direction], className)}
    >
      {title && <title>{title}</title>}
      {content}
    </svg>
  )
}

export default Icon
