import { baseUrl } from '../constants'

interface Slug {
  current: string
  pretty: string
}

export const topicSlugs = {
  concept: 'konsept',
  event: 'hendelse',
  organization: 'organisasjon',
  person: 'person',
}

const urls = {
  root: (): string => baseUrl,

  articlePath(slug: Slug): string {
    return `artikler/${slug.current}/${slug.pretty}`
  },

  article(slug: Slug): string {
    return `${baseUrl}/${urls.articlePath(slug)}`
  },

  oembed(url: string): string {
    return `${baseUrl}/oembed?url=${encodeURIComponent(url)}`
  },

  search(): string {
    return `${baseUrl}/sok`
  },

  tema(slug?: string, type?: 'concept' | 'event' | 'organization' | 'person'): string {
    if (!slug || !type) {
      return '/'
    }
    return `${baseUrl}/tema/${topicSlugs[type] || type}/${slug}`
  },

  logo(): string {
    // FIXME
    return `${baseUrl}/images/logo.png`
  },

  atomChanges(): string {
    return `${baseUrl}/atom.changes.xml`
  },

  atom(): string {
    return `${baseUrl}/atom.xml`
  },
}

export default urls
