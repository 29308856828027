import React, { useEffect } from 'react'
import Head from 'next/head'
import pym from 'pym.js'

import BEMHelper from '../../helpers/bem'
import usePageBackground from '../../hooks/usePageBackground'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

declare global {
  interface Window {
    pym
  }
}

if (process.browser) {
  window.pym = pym
}

interface Props {
  children: JSX.Element[] | JSX.Element | string
}

const EmbedLayout: React.FC<Props> = ({ children }) => {
  usePageBackground(true, 0, 'light')

  useEffect(() => {
    const pymChild = new pym.Child({
      polling: 100,
    })

    return () => pymChild.remove()
  }, [])

  return (
    <>
      <Head>
        <base target="_top" />
      </Head>

      <div {...bem('')}>
        <main {...bem('content')}>{children}</main>
      </div>
    </>
  )
}

export default EmbedLayout
