import { useEffect } from 'react'
import { useRouter } from 'next/router'

import useIsEmbedded from './useIsEmbedded'

let _TIMER
const ANIMATION_DURATION = 1500

export default function usePageAnimation(): void {
  const router = useRouter()
  const isEmbed = useIsEmbedded()

  useEffect(() => {
    const handleRouteChangeStart = () => {
      // console.log('Page enter waiting...')
      clearTimeout(_TIMER)
      document.body.setAttribute('data-page-state', 'waiting')
    }

    const handleRouteChangeComplete = () => {
      // console.log('Page enter...')
      if (!isEmbed) {
        document.body.setAttribute('data-page-state', 'entering')

        clearTimeout(_TIMER)
        _TIMER = setTimeout(() => {
          // console.log('Done...')
          document.body.setAttribute('data-page-state', 'entered')
        }, ANIMATION_DURATION)
      }
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)

      clearTimeout(_TIMER)
      document.body.removeAttribute('data-page-state')
    }
  }, [])
}
