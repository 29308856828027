/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import styles from './Styles.module.scss'
import Icon from '../Icon'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)
const CLOSE_TEXT = 'Lukk'
const KEY_ESC = 27
const ANIMATION_DURATION = 800

interface Props {
  title?: string
  preamble?: string
  disclaimer?: string
  expanded: boolean
  full?: boolean
  width?: number
  top?: number
  onClose: () => void
  preventClickOutside?: boolean
  children: React.ReactNode
}

const Modal: React.FC<Props> = ({
  children,
  title,
  preamble,
  disclaimer,
  expanded,
  full,
  width,
  onClose,
  preventClickOutside,
  top,
}) => {
  const initialRender = useRef(true)
  const contentRef = useRef<any>(null)
  const wrapperRef = useRef<any>(null)
  const [show, setShow] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)

  useEffect(() => {
    wrapperRef.current = document.body
  }, [])

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false

      return
    }

    if (show) {
      contentRef.current.focus({ preventScroll: true })
    }
  }, [show])

  useEffect(() => {
    if (expanded !== show) {
      setShow(expanded)

      if (expanded) {
        document.body.classList.add('block-scrolling')
      }

      if (!expanded) {
        setAnimateOut(true)
      }
    }
  }, [expanded, show])

  useEffect(() => {
    let _timer
    if (animateOut) {
      clearTimeout(_timer)

      _timer = setTimeout(() => {
        if (animateOut) {
          setAnimateOut(false)
          document.body.classList.remove('block-scrolling')
        }
      }, ANIMATION_DURATION)
    }

    return () => {
      clearTimeout(_timer)
      document.body.classList.remove('block-scrolling')
    }
  }, [animateOut])

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEY_ESC) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleBackdropClick = () => {
    if (preventClickOutside) {
      contentRef.current.focus()
    } else {
      onClose()
    }
  }

  const styles = {
    ...(top ? { '--top': `${top}px` } : {}),
    ...(width ? { '--width': `${width}px` } : {}),
  } as React.CSSProperties

  if (show || animateOut) {
    return createPortal(
      <aside
        {...bem('', { top, full, exiting: animateOut, entering: show && !animateOut, width })}
        role="dialog"
        aria-modal="true"
        style={styles}
      >
        <button
          type="button"
          {...bem('hidden-close')}
          aria-label={CLOSE_TEXT}
          onFocus={handleBackdropClick}
        />

        <div {...bem('wrapper')} ref={contentRef} tabIndex={0}>
          <div {...bem('content')}>
            <button type="button" {...bem('close')} onClick={onClose} aria-label={CLOSE_TEXT}>
              <Icon icon="close" />
            </button>
            {title && (
              <header {...bem('header')}>
                <h2 {...bem('title')}>{title}</h2>
                {preamble && <p {...bem('preamble')}>{preamble}</p>}
              </header>
            )}

            {children}

            {disclaimer && <p {...bem('disclaimer')}>{disclaimer}</p>}
          </div>
        </div>

        <button
          type="button"
          aria-label={CLOSE_TEXT}
          {...bem('backdrop', {
            'animate-out': animateOut,
            'prevent-close': preventClickOutside,
          })}
          onFocus={handleBackdropClick}
          onClick={handleBackdropClick}
        />
      </aside>,
      wrapperRef.current,
    )
  }

  return null
}

export default Modal
