import React from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

interface Props {
  children: React.ReactNode
  size?: 'base' | 'medium' | 'tight' | 'xtight' | 'fluid'
  className?: string
  breakOut?: boolean
  node?: keyof JSX.IntrinsicElements
}

const Container: React.FC<Props> = ({ children, size, className, node = 'div', breakOut }) => {
  const Node = node || 'div'

  if (breakOut) {
    return (
      <div {...bem('wrapper')}>
        <Node {...bem('', size, className)}>{children}</Node>
      </div>
    )
  }
  return <Node {...bem('', size, className)}>{children}</Node>
}

export default Container
