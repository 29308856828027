import React, { useRef, useEffect, useCallback, useLayoutEffect } from 'react'
import { debounce } from 'lodash'
import { useRouter } from 'next/router'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

const Search: React.FC = () => {
  const inputRef = useRef<any>()
  const router = useRouter()

  function resizeInput() {
    if (!inputRef.current) {
      return
    }

    inputRef.current.style.width = '0px'
    inputRef.current.style.width = `${inputRef.current.scrollWidth + 4}px`
  }

  useLayoutEffect(() => {
    setTimeout(resizeInput, 0)
  })

  useEffect(() => {
    inputRef.current.value = router?.query?.q || ''

    // initial input size calculation
    setTimeout(resizeInput, 0)

    /**
     * set up events to resize input size on window resize (e.g. flipping
     * phone, or maximizing browser window)
     */
    window.addEventListener('resize', resizeInput)
    return () => window.removeEventListener('resize', resizeInput)
  }, [])

  useEffect(() => {
    if (router.route !== '/sok') {
      inputRef.current.value = ''
    }
  }, [router.route])

  const updateUrl = useCallback(
    debounce((q) => {
      router.replace({
        pathname: '/sok',
        query: {
          q,
          ...(router.query.sort ? { sort: router.query.sort } : {}),
        },
      })
    }, 400),
    [],
  )

  const handleSubmit = (event: any) => {
    event.preventDefault()
    updateUrl(inputRef.current.value)
  }

  const handleChange = ({ target: { value } }) => {
    inputRef.current.style.width = '0px'
    inputRef.current.style.width = `${inputRef.current.scrollWidth + 4}px`

    updateUrl(value)
  }
  return (
    <form onSubmit={handleSubmit} {...bem('')}>
      <input
        type="search"
        ref={inputRef}
        autoComplete="off"
        spellCheck="false"
        autoFocus
        placeholder="Søk."
        onChange={handleChange}
        {...bem('input')}
      />
    </form>
  )
}

export default Search
