import React, { useEffect } from 'react'

import '../../styles/styles.scss'
import Layout from '../components/Layout'
import EmbedLayout from '../components/EmbedLayout'
import { useRouter } from 'next/router'
import * as Fathom from 'fathom-client'

interface Props {
  Component: any
  pageProps?: Array<any>
  router: {
    route: any
  }
}

export const EmbedContext = React.createContext(false)

const App: React.FC<Props> = ({ Component, pageProps, router: { route } }) => {
  const router = useRouter()

  useEffect(() => {
    Fathom.load('PKCCENUV', {
      excludedDomains: ['faktisk-no.herokuapp.com', 'localhost:3000'],
    })

    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])

  const isEmbed = !!route.match(/\/embed$/)

  if (isEmbed) {
    return (
      <EmbedContext.Provider value={true}>
        <EmbedLayout>
          <Component {...pageProps} />
        </EmbedLayout>
      </EmbedContext.Provider>
    )
  }

  return (
    <EmbedContext.Provider value={false}>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </EmbedContext.Provider>
  )
}

export default App
