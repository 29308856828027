import { useState } from 'react'

export default function useToggle(
  initialValue?: boolean,
  callback?: (value: boolean) => void,
): Array<any> {
  const [expanded, setExpanded] = useState(initialValue || false)
  const toggle = (value: boolean) => () => {
    setExpanded(value)

    if (callback) {
      callback(value)
    }
  }

  return [expanded, toggle]
}
