import { useEffect } from 'react'

export default function useBlockScrolling(listener: boolean): void {
  useEffect(() => {
    if (listener) {
      document.body.classList.add('block-scrolling')
    } else {
      document.body.classList.remove('block-scrolling')
    }

    return () => document.body.classList.remove('block-scrolling')
  }, [listener])
}
