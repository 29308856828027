import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)

interface Props {
  type?: string
  className?: string
  children: React.ReactNode
}

const Message: React.FC<Props> = ({ children, type, className }) => {
  return <p {...bem('', type, className)}>{children}</p>
}

export default Message
