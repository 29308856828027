import { useEffect } from 'react'

let _TIMER

export default function usePageBackground(
  listener: boolean,
  delay?: number,
  type: 'dark' | 'light' = 'dark',
): void {
  useEffect(() => {
    clearTimeout(_TIMER)

    if (listener) {
      if (delay) {
        _TIMER = setTimeout(() => {
          document.body.classList.add(type)
        }, delay)
      } else {
        document.body.classList.add(type)
      }
    } else {
      document.body.classList.remove(type)
    }

    return () => {
      clearTimeout(_TIMER)
      document.body.classList.remove(type)
    }
  }, [listener])
}
