import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)

interface Props {
  onSubmit: (event) => void
  children: React.ReactNode
}

const Form: React.FC<Props> = ({ children, onSubmit }) => {
  return (
    <form {...bem('')} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default Form
