import React from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

const TrampStamp: React.FC = () => {
  return (
    <svg
      {...bem('stamp')}
      width="117"
      height="117"
      viewBox="0 0 117 117"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M39.0332 90.4011C39.0332 90.4011 46.9536 95.9479 58.6149 95.9479C70.2762 95.9479 78.1966 90.4011 78.1966 90.4011V68.7812L59.6727 31.8882V64.8597L61.556 68.3942L58.5891 73.2703L55.6738 68.42L57.5571 64.8855V31.914L39.0332 68.42V90.4011Z" />
      <path d="M58.5896 105.571C32.6355 105.571 11.5059 84.4414 11.5059 58.4873H13.0796C13.0796 83.5901 33.4869 103.997 58.5896 103.997C83.6924 103.997 104.1 83.5901 104.1 58.4873H105.673C105.673 84.4672 84.5438 105.571 58.5896 105.571Z" />
      <path d="M58.5905 96.1545C37.822 96.1545 20.9492 79.2559 20.9492 58.5132H21.3362C21.3362 79.0495 38.0542 95.7675 58.5905 95.7675C79.1267 95.7675 95.8447 79.0495 95.8447 58.5132H96.2317C96.2317 79.2559 79.3589 96.1545 58.5905 96.1545Z" />
      <path d="M11.7637 52.218L12.3055 49.5348C12.5119 48.5544 12.9504 47.7805 13.6212 47.3419C14.2146 46.9291 15.0144 46.8001 15.9948 47.0065L16.0722 47.0323C17.4395 47.3161 18.1361 48.0901 18.4457 49.0188L22.4446 48.0901L22.0318 50.1024L18.5231 50.8764L18.3683 51.6762L21.5932 52.3212L21.232 54.1271L11.7637 52.218ZM16.9493 50.6184C17.1557 49.638 16.6655 49.0188 15.7368 48.8124C14.7306 48.606 14.1372 49.0446 13.9308 50.0508L13.8018 50.7216L16.7945 51.315L16.9493 50.6184Z" />
      <path d="M14.2402 42.4919L16.2268 37.8738L17.8005 38.5446L16.5364 41.4857L18.6003 42.3629L19.7097 39.7571L21.2835 40.4279L20.1741 43.0336L22.2896 43.9366L23.5538 40.9439L25.1276 41.6147L23.141 46.2844L14.2402 42.4919Z" />
      <path d="M18.2129 34.1586L19.5803 32.1979C21.025 30.1339 23.347 29.9017 25.7721 31.6303L25.9527 31.7593C28.3778 33.462 28.9454 35.7066 27.5007 37.7963L26.1333 39.7571L18.2129 34.1586ZM20.6638 33.6168L25.8237 37.2545L26.1075 36.8417C26.8557 35.784 26.5977 34.5456 24.8175 33.3072L24.7143 33.2298C22.96 31.9915 21.6958 32.1721 20.9476 33.2298L20.6638 33.6168Z" />
      <path d="M26.0039 24.6128L27.3713 23.3486L35.9367 28.6891L34.5435 29.9791L32.7891 28.8439L30.9574 30.5208L31.9378 32.3526L30.5962 33.591L26.0039 24.6128ZM31.3444 27.9151L28.6096 26.1092L30.1576 28.9987L31.3444 27.9151Z" />
      <path d="M32.5586 19.324L34.1582 18.3952L36.2221 21.9555L36.1705 17.2342L38.0281 16.1506L37.9249 20.9493L42.9299 24.5096L41.0724 25.5674L37.6153 23.1423L37.5895 24.4064L38.9568 26.78L37.3573 27.7087L32.5586 19.324Z" />
      <path d="M43.2126 16.0216L41.4582 16.615L40.8906 14.9638L46.1279 13.2095L46.6955 14.8606L44.9411 15.454L47.4695 22.9874L45.7151 23.5808L43.2126 16.0216Z" />
      <path d="M56.3713 11.4551L55.7263 12.9514C56.3455 13.6996 56.7841 14.7832 56.9389 16.1506L56.9647 16.4602C57.3259 19.4787 56.1133 21.4394 54.0494 21.6974C53.3528 21.7748 52.7078 21.6458 52.166 21.3362L51.9338 21.878L50.5922 22.0328L51.2372 20.5364C50.618 19.7883 50.1794 18.7047 50.0247 17.3373L49.9989 17.0277C49.6377 14.0092 50.8502 12.0485 52.9142 11.7905C53.6108 11.7131 54.2557 11.8421 54.8233 12.1517L55.0555 11.6099L56.3713 11.4551ZM52.1144 18.4467L54.101 13.8286C53.7914 13.5448 53.4302 13.4158 53.069 13.4674C52.1144 13.5706 51.5468 14.6542 51.8306 16.8471L51.8564 17.0793C51.908 17.5953 52.0112 18.0597 52.1144 18.4467ZM55.1329 16.6923L55.1071 16.4602C55.0555 15.9184 54.9523 15.454 54.8233 15.067L52.8368 19.6851C53.1464 19.9689 53.5076 20.0979 53.8946 20.0463C54.8233 19.9431 55.3909 18.8595 55.1329 16.6923Z" />
      <path d="M60.6534 11.5583L63.3881 11.8421C64.3943 11.9453 65.1941 12.3323 65.7101 12.9515C66.1745 13.5191 66.3809 14.2931 66.2777 15.2993V15.3767C66.1229 16.744 65.4263 17.5438 64.5233 17.9308L65.8391 21.8265L63.8009 21.6201L62.6915 18.2146L61.8918 18.1372L61.5564 21.3879L59.7246 21.2073L60.6534 11.5583ZM62.7431 16.5634C63.7493 16.6666 64.3169 16.1248 64.4201 15.1961C64.5233 14.1899 64.0331 13.6223 63.0269 13.5191L62.3304 13.4417L62.0208 16.486L62.7431 16.5634Z" />
      <path d="M72.677 13.6997L74.4571 14.2931L73.9411 24.3806L72.1352 23.7872L72.2642 21.6975L69.9165 20.8977L68.7813 22.6521L67.0527 22.0845L72.677 13.6997ZM72.3674 19.9689L72.6254 16.6924L70.8452 19.453L72.3674 19.9689Z" />
      <path d="M80.5445 16.8987L82.0151 17.8017L81.6023 23.7355L84.3628 19.2206L85.9108 20.1752L80.8541 28.431L79.4868 27.6054L79.9254 21.4136L77.0616 26.109L75.5137 25.1545L80.5445 16.8987Z" />
      <path d="M82.9707 28.5857L84.7509 28.3019C84.7767 28.8695 84.8025 29.7467 85.3958 30.3143C85.886 30.7787 86.4794 30.7271 86.8664 30.3401C87.1502 30.0305 87.2276 29.6435 86.8922 28.4051C86.4278 26.754 86.3246 25.6188 87.2534 24.6384L87.2792 24.6126C88.4144 23.4259 90.0139 23.4517 91.2781 24.6384C91.8715 25.206 92.4133 26.1348 92.4133 27.2442L90.6073 27.5022C90.6073 26.9862 90.5299 26.2896 90.0397 25.8252C89.6269 25.4382 89.1109 25.4124 88.7497 25.7736C88.4402 26.109 88.3628 26.496 88.7755 28.0181C89.1883 29.5661 89.1883 30.5981 88.3112 31.5269L88.2854 31.5527C87.1502 32.7652 85.4216 32.6878 84.2349 31.5785C83.5641 30.9077 82.9965 29.9273 82.9707 28.5857Z" />
      <path d="M95.0959 28.6633L96.2311 30.2629L91.8194 35.268L97.9854 32.7396L99.0948 34.2876L89.7038 37.8479L88.6719 36.3773L95.0959 28.6633Z" />
      <path d="M101.416 38.8281L102.165 40.5309L94.3732 46.9291L93.5992 45.2006L95.2246 43.9106L94.2184 41.6403L92.1544 42.0014L91.4062 40.3245L101.416 38.8281ZM96.5661 42.827L99.1461 40.8147L95.8953 41.3565L96.5661 42.827Z" />
      <path d="M104.305 46.7485L104.846 49.4317C105.053 50.412 104.924 51.315 104.485 51.96C104.073 52.5534 103.402 52.992 102.421 53.1984L102.344 53.2242C100.977 53.4822 100.022 53.0436 99.377 52.2954L96.0231 54.6947L95.6103 52.6824L98.5514 50.6184L98.3967 49.8187L95.1717 50.4636L94.8105 48.6577L104.305 46.7485ZM100.151 50.2057C100.357 51.186 101.054 51.573 101.957 51.3924C102.963 51.186 103.35 50.541 103.144 49.5607L103.015 48.8899L100.022 49.4833L100.151 50.2057Z" />
    </svg>
  )
}

export default TrampStamp
