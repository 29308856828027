import React from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

import Container from '../Container'
import Link from '../Link'
import Facts, { Box } from '../Facts'

const bem = BEMHelper(styles)

interface Props {
  exiting?: boolean
}

const LINKS = {
  INTERNAL: [
    ['Om oss.', '/om-oss'],
    ['Slik jobber vi.', '/slik-jobber-vi'],
    ['Kurs og foredrag.', '/kurs-og-foredrag'],
    ['Ansatte.', '/ansatte'],
  ],
  CONTENT: [
    ['Faktasjekk.', '/sjangere/faktasjekk'],
    ['Artikkel.', '/sjangere/artikkel'],
    ['Kommentar.', '/sjangere/kommentar'],
    //['Guide', '/sjangere/guide'],
  ],
  FOOTER: [
    ['Finansiering.', '/finansiering'],
    ['Statistikk.', '/statistikk'],
    ['Personvern.', '/personvern'],
  ],
}

const Menu: React.FC<Props> = ({ exiting }) => {
  return (
    <nav {...bem('', { exiting })}>
      <Container node="div" {...bem('content')}>
        <div {...bem('prioritized')}>
          <Facts fluid noReveal flipMobile>
            <Box title="Om oss" type="large">
              <div {...bem('links')}>
                {LINKS.INTERNAL.map(([label, slug]) => (
                  <Link key={slug} href={slug} {...bem('link', 'internal')}>
                    {label}
                  </Link>
                ))}
              </div>
            </Box>

            <Box title="Innhold" type="large">
              <div {...bem('links')}>
                {LINKS.CONTENT.map(([label, slug]) => (
                  <Link key={slug} href={slug} {...bem('link', 'content')}>
                    {label}
                  </Link>
                ))}
              </div>
            </Box>
          </Facts>
        </div>

        <div {...bem('footer')}>
          {LINKS.FOOTER.map(([label, slug]) => (
            <Link key={slug} href={slug} {...bem('small-link')}>
              {label}
            </Link>
          ))}
        </div>
      </Container>
    </nav>
  )
}

export default Menu
