import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

import Container from '../Container'
import Logo from '../Logo'
import Link from '../Link'
import Menu from '../Menu'
import Search from '../Search'
import useESC from '../../hooks/useESC'
import useToggle from '../../hooks/useToggle'
import useBlockScrolling from '../../hooks/useBlockScrolling'
import usePageBackground from '../../hooks/usePageBackground'

const bem = BEMHelper(styles)

const ANIMATION_OUT_DURATION = 300

const Header: React.FC = () => {
  const router = useRouter()
  const previousPath = useRef<any>(null)

  const [animateOut, setAnimateOut] = useState<string | null>(null)
  const [menu, toggleMenu] = useToggle(false, (value) => {
    if (!value) {
      setAnimateOut('menu')
    }
  })
  const [search, toggleSearch] = useToggle(false, (value) => {
    if (!value) {
      setAnimateOut('search')
    }
  })

  useBlockScrolling(menu)
  usePageBackground(search, 500)

  useESC(() => {
    if (menu) {
      toggleMenu(false)()
    }
    if (search) {
      closeSearch()
    }
  }, [menu, search])

  const handleRouteChange = useCallback(
    (path: string) => {
      if (menu) {
        toggleMenu(false)()
        setTimeout(() => {
          setAnimateOut(null)
        }, 0)
      }

      if (!path.startsWith('/sok') && search) {
        toggleSearch(false)()
      }
    },
    [search, menu, toggleMenu, toggleSearch],
  )

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, search, menu, handleRouteChange])

  useEffect(() => {
    let _timer: NodeJS.Timeout
    if (animateOut) {
      _timer = setTimeout(() => {
        setAnimateOut(null)
      }, ANIMATION_OUT_DURATION)
    }
    return () => clearTimeout(_timer)
  }, [animateOut])

  useEffect(() => {
    if (router.asPath.startsWith('/sok') && !search) {
      toggleSearch(true)()
    }
  }, [ router.asPath, search, toggleSearch])

  const setSearchMode = () => {
    toggleSearch(true)()
    previousPath.current = !router.asPath.startsWith('/sok') ? router.asPath : '/'

    // router.replace({ pathname: '/sok' })
  }

  const closeSearch = () => {
    toggleSearch(false)()
    router.replace({ pathname: previousPath.current || '/' })
  }

  const showSearch = search || animateOut === 'search'
  const showMenu = menu || animateOut === 'menu'

  const frontpage = router.asPath === '/'
  const LogoNode = frontpage ? 'h1' : 'span'

  return (
    <>
      <Container
        node="header"
        {...bem('', {
          menu,
          'menu-exiting': animateOut === 'menu',
          search,
          'search-exiting': animateOut === 'search',
        })}
      >
        <LogoNode {...bem('column', 'first')}>
          <Link href="/" {...bem('logo')}>
            <Logo />
          </Link>
        </LogoNode>

        <span {...bem('column', 'middle')}>
          <button type="button" {...bem('search')} onClick={setSearchMode}>
            Søk.
          </button>
        </span>

        <span {...bem('column', 'last')}>
          {search ? (
            <button type="button" {...bem('toggle')} onClick={closeSearch}>
              Lukk.
            </button>
          ) : (
            <button type="button" {...bem('toggle')} onClick={toggleMenu(!menu)}>
              {menu ? 'Lukk.' : 'Meny.'}
            </button>
          )}
        </span>

        {showSearch && (
          <div {...bem('search-wrapper', { exiting: animateOut })}>
            <div {...bem('search-content')}>
              <Search />
            </div>
          </div>
        )}
      </Container>

      {showMenu && <Menu exiting={animateOut === 'menu'} />}
      {showSearch && <div {...bem('backdrop', { exiting: animateOut })} />}
    </>
  )
}

export default Header
