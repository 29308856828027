const windowLocation =
  typeof window !== 'undefined' ? `${window.location.protocol}//${window.location.host}` : ''

export const baseUrl = process.env.BASE_URL || windowLocation || 'https://www.faktisk.no'

export const atomChanges = `${baseUrl}/atom.changes.xml`

export const title = 'Faktisk'

export const description =
  'Vi faktasjekker det offentlige ordskiftet og avdekker falske påstander og historier som sprer seg i det norske samfunnet.'

export const LEGACY_RATINGS: Record<string, string> = {
  1: 'helt sann',
  2: 'delvis sann',
  3: 'ikke sikker',
  4: 'delvis feil',
  5: 'helt feil',
}

export const IMAGE_SIZES: Record<string, number[]> = {
  full: [760, 1400],
  default: [760, 1260],
  medium: [760, 1260],
  'right-large': [760, 1000],
  right: [300, 800],
  facsimile: [300, 800],
  'right-small': [300, 500],
  'right-xsmall': [200, 300],
  'facsimile-small': [300, 500],
}

export const COLORS = ['#76D0F6', '#000000', '#F5BC65', '#F06565', '#81D459', '#968FF6', '#C89254']
