import React, { useRef } from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import Container from '../Container'
import useIntersection from '../../hooks/useIntersection'

const bem = BEMHelper(styles)

interface Props {
  children: JSX.Element[]
  className?: string
  fluid?: boolean
  wide?: boolean
  noReveal?: boolean
  small?: boolean
  flipMobile?: boolean
}

const Facts: React.FC<Props> = ({
  children,
  className,
  fluid,
  wide,
  small,
  noReveal,
  flipMobile,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const reveal = useIntersection(wrapperRef)

  const size = (fluid && 'fluid') || (wide && 'base') || (small && 'xtight') || 'medium'

  return (
    <div ref={wrapperRef}>
      <Container
        size={size}
        {...bem(
          '',
          {
            waiting: !noReveal && !reveal,
            animate: reveal || noReveal,
            fluid,
            small,
            'flip-mobile': flipMobile,
          },
          className,
        )}
      >
        {children}
      </Container>
    </div>
  )
}

interface BoxProps {
  title: string
  children: JSX.Element[] | JSX.Element | string
  extra?: JSX.Element[] | JSX.Element | string
  type?: string[] | string
}

export const Box: React.FC<BoxProps> = ({ title, children, extra, type }) => {
  return (
    <section {...bem('group', type)}>
      <span {...bem('line', 'left')} />
      <span {...bem('line', 'top')} />
      <div {...bem('content')}>
        <h2 {...bem('title')}>{title}</h2>
        <div {...bem('text')}>{children}</div>
        {extra && <div {...bem('extra')}>{extra}</div>}
      </div>
    </section>
  )
}

export default Facts
