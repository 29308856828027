import React from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

import Link from '../Link'
import Container from '../Container'
import TrampStamp from './TrampStamp'

const bem = BEMHelper(styles)

// Not to repeat it so much
const linkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

const Footer: React.FC = () => {
  return (
    <Container node="footer" {...bem('')}>
      <div {...bem('content')}>
        <section {...bem('group')}>
          <h2 {...bem('title')}>Våre retningslinjer</h2>
          <p {...bem('text')}>
            <a href="http://presse.no/pfu/etiske-regler/vaer-varsom-plakaten/" {...linkProps}>
              Vær varsom- plakatens
            </a>{' '}
            regler for god presseskikk,{' '}
            <a href="https://www.nored.no/Redaktoeransvar/Redaktoerplakaten" {...linkProps}>
              Redaktørplakaten
            </a>{' '}
            og{' '}
            <a
              href="https://www.faktisk.no/vedtekter-etikkregler-og-faktasjekkplakaten"
              {...linkProps}
            >
              Faktasjekkplakaten
            </a>
            .
          </p>
        </section>

        <section {...bem('group')}>
          <h2 {...bem('title')}>Ta kontakt</h2>

          <p {...bem('text')}>
            Send e-post til{' '}
            <a href="mailto:kontakt@faktisk.no" {...linkProps}>
              kontakt@faktisk.no
            </a>{' '}
            om ris, ros og urettmessig publisering. Les om{' '}
            <Link href="/slik-jobber-vi#rettelser-og-klager">rettelser og klager</Link> eller{' '}
            <Link href="/personvern">personvern</Link>.
          </p>
        </section>

        <section {...bem('group')}>
          <h2 {...bem('title')}>Ansvarlig redaktør</h2>

          <p {...bem('text')}>
            Stian Eisenträger
            <br />
            E-post:{' '}
            <a href="mailto:stian@faktisk.no" {...linkProps}>
              stian@faktisk.no
            </a>
          </p>
        </section>

        <section {...bem('group')}>
          <h2 {...bem('title')}>Faktisk.no AS</h2>

          <p {...bem('text')}>
            Orgnr. 919 036 508
            <br />
            <a href="https://www.facebook.com/faktisk" {...linkProps}>
              Facebook
            </a>{' '}
            /{' '}
            <a href="https://twitter.com/faktisk_no" {...linkProps}>
              Twitter
            </a>{' '}
            / <a href="/atom.xml">RSS</a>
          </p>
        </section>
      </div>

      <TrampStamp />
    </Container>
  )
}

export default Footer
