import { useEffect } from 'react'

const KEY_ESC = 27

export default function useESC(fnc: () => void, listeners: Array<any>): void {
  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEY_ESC) {
      fnc()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, listeners || [])
}
