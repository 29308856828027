import React, { useRef, useState } from 'react'
import axios from 'axios'

import Facts, { Box } from '../Facts'
import Button from '../Button'
import Modal from '../Modal'
import Input from '../Input'
import Upload from '../Upload'
import Form from '../Form'
import Accordion from '../Accordion'
import Message from '../Message'

const FORM_DEFAULTS = {
  name: '',
  email: '',
  description: '',
}

const VippsTips: React.FC = () => {
  const files = useRef<HTMLInputElement>(null)
  const [form, setForm] = useState(FORM_DEFAULTS)
  const [showTips, setShowTips] = useState(false)
  const [tipsCompleted, setTipsCompleted] = useState(false)
  const [tipsError, setTipsError] = useState<string>()

  const [showSupport, setShowSupport] = useState(false)

  const handleForm = (key: string) => (value: string) => {
    setForm((form) => ({ ...form, [key]: value }))
  }

  const toggleTips = (value: boolean) => (): void => {
    setShowTips(value)

    if (value) {
      setTipsCompleted(false)
      setTipsError(undefined)
    }
  }

  const submitForm = async (event): Promise<void> => {
    event.preventDefault()
    setTipsError(undefined)

    if (!form.email || !form.description) {
      setTipsError('E-post og tipstekst må fylles ut')
      return
    }

    const bodyFormData = new FormData()

    bodyFormData.append('name', form.name)
    bodyFormData.append('email', form.email)
    bodyFormData.append('description', form.description)

    if (files) {
      const fileCount = files?.current?.files?.length || 0

      for (let i = 0; i < fileCount; i++) {
        if (files?.current?.files?.[i]) {
          bodyFormData.append(`file[]`, files?.current?.files?.[i])
        }
      }
    }

    try {
      const { data } = await axios({
        method: 'post',
        url: '/api/tips',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      if (data.success) {
        setTipsCompleted(true)
        setForm(FORM_DEFAULTS)
      } else {
        setTipsError('En feil oppsto. Tipset ble ikke sendt')
      }
    } catch (e) {
      if (e.response.status === 413) {
        setTipsError('Filen du har lastet opp er for stor. Maks 7MB')
        return
      }

      setTipsError(e.response?.data || e.response.statusText)
    }
  }

  const toggleSupport = (value: boolean) => (): void => {
    setShowSupport(value)
  }

  return (
    <>
      <Facts>
        <Box
          title="Vipps oss"
          type="large"
          extra={<Button label="Støtt oss" onClick={toggleSupport(true)} />}
        >
          Ønsker du mer fakta i hverdagen?
        </Box>

        <Box
          title="Tips oss"
          type="large"
          extra={<Button label="Kontakt oss" onClick={toggleTips(true)} />}
        >
          Vet du om noe vi bør faktasjekke?
        </Box>
      </Facts>

      <Modal
        title="Tips oss"
        preamble={
          tipsCompleted ? 'Ditt tips har nå blitt sendt til Faktisk-redaksjonen.' : undefined
        }
        expanded={showTips}
        onClose={toggleTips(false)}
        preventClickOutside={Object.values(form).some((item) => item.length)}
      >
        {tipsCompleted ? (
          <Button icon="check" large center onClick={toggleTips(false)} />
        ) : (
          <Form onSubmit={submitForm}>
            <Input label="Navn" value={form.name} onChange={handleForm('name')} />
            <Input label="E-post" type="email" value={form.email} onChange={handleForm('email')} />
            <Input
              label="Ditt tips"
              value={form.description}
              onChange={handleForm('description')}
              textarea
            />
            <Upload label="Vedlegg" ref={files} multiple={false} />
            <Button type="submit" label="Send" primary large center />

            {tipsError && <Message>{tipsError}</Message>}
          </Form>
        )}
      </Modal>

      <Modal
        title="Støtt Faktisk.no"
        preamble="Vårt arbeid er avhengig av støtte fra enkeltpersoner og virksomheter."
        disclaimer="Faktisk.no er et ideelt selskap. Alle midler går til selskapets formål. Din støtte bidrar til arbeidet med å faktasjekke samfunnsdebatten og forhindre spredningen av falske og oppdiktede meldinger. Vi er åpne om vår finansiering. Alle bidrag kan bli offentliggjort. For større bidrag eller spørsmål om vår finansiering, kontakt ansvarlig redaktør Kristoffer Egeberg eller styreleder Helje Solberg."
        expanded={showSupport}
        onClose={toggleSupport(false)}
      >
        <Accordion title="Vipps" large center>
          <p>Vipps til Faktisk AS</p>
          <p>Nr. 124662</p>
        </Accordion>
        <Accordion title="Bank" large center>
          <p>Overfør til kontonr. 1506.05.79730</p>
          <p>
            Fra utlandet, bruk IBAN: NO1015060579730,
            <br />
            BIC/SWIFT: DNBANOKKXXX
          </p>
        </Accordion>
      </Modal>
    </>
  )
}

export default VippsTips
