import urlHelper from './urls'
import { LEGACY_RATINGS, IMAGE_SIZES } from '../constants'
import { capitalize } from 'lodash'
import portableToPlain from './portableToPlain'
import { getImageUrl } from '../api/sanity'
import { Article as ArticleType } from '../types/article'

const context = 'http://schema.org'

const schemaTypes = {
  organization: 'Organization',
  person: 'Person',

  // other types not supported, according to https://developers.google.com/search/docs/data-types/factcheck
  event: 'Event',
  location: 'Place',
  concept: 'Thing',
}

export type SchemaType = Record<string, unknown>

export const organization = (): SchemaType => {
  return {
    '@type': 'Organization',
    url: urlHelper.root(),
    sameAs: [
      'https://twitter.com/faktisk_no',
      'https://facebook.com/faktisk',
      'https://www.instagram.com/faktisk_no',
    ],
    name: 'Faktisk',
    logo: {
      '@type': 'ImageObject',
      url: urlHelper.logo(),
    },
  }
}

export const website = (): SchemaType => {
  return {
    '@context': context,
    '@type': 'WebSite',
    name: 'Faktisk',
    alternateName: 'Faktisk.no',
    url: urlHelper.root(),
    potentialAction: {
      '@type': 'SearchAction',
      target: urlHelper.search() + `?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }
}

export const article = (data: ArticleType): SchemaType => {
  return {
    '@context': context,
    '@type': 'NewsArticle',
    description: data.shareDescription,
    publisher: organization(),
    author: organization(),
    headline: data.title,
    dateCreated: data._createdAt,
    dateModified: data._updatedAt,
    datePublished: data.publishedAt,
    keywords: data?.tags?.map((e) => e.name)?.join(', ') || '',
    image: data.mainImage
      ? {
          '@type': 'ImageObject',
          url: getImageUrl(data.mainImage).width(IMAGE_SIZES.default[2]).url(),
        }
      : null,
    mainEntityOfPage: website(),
  }
}

const tags = (items) => items.map((e) => tag(e))

export const tag = (data: { _type: string; name: string }): SchemaType => {
  return {
    '@type': schemaTypes[data._type] || 'Thing',
    name: data.name,
  }
}

export const claimConclusion = (cc, article: ArticleType): SchemaType => {
  let reviewRating

  if (cc.rating) {
    reviewRating = {
      '@type': 'Rating',
      alternateName: capitalize(LEGACY_RATINGS[cc.rating] || 'ukjent'),
      // invert rating to comply with schema
      ratingValue: 6 - cc.rating,
      bestRating: 5,
      worstRating: 1,
    }
  } else {
    // no rating - Full Fact uses conclusion as alternateName
    reviewRating = {
      '@type': 'Rating',
      alternateName: portableToPlain(cc.conclusion),
      ratingValue: null,
      bestRating: null,
      worstRating: null,
    }
  }

  return {
    '@context': context,
    '@type': 'ClaimReview',
    datePublished: article.publishedAt,
    dateModified: article._updatedAt,
    url: urlHelper.article(article.slug),
    author: organization(),
    image: article.mainImage
      ? {
          '@type': 'ImageObject',
          url: getImageUrl(article.mainImage).width(IMAGE_SIZES.default[1]).url(),
        }
      : null,
    description: article.title,
    claimReviewed: cc.quote,
    reviewRating,
    reviewBody: portableToPlain(cc.conclusion),
    itemReviewed: {
      '@type': 'CreativeWork',
      author: cc.claimants.length ? tags(cc.claimants) : null,
      datePublished: cc.claimAt,
      sameAs: cc.sourceUrl ? [cc.sourceUrl] : undefined,
    },
    keywords: article.tags.map((t) => t.name),
    mainEntityOfPage: website(),
  }
}
