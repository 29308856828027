import React, { forwardRef, useState } from 'react'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

import Icon from '../Icon'

const bem = BEMHelper(styles)

interface Props {
  className?: string
  label?: string
  multiple?: boolean
  onChange?: (value: string) => void // TODO..
}

interface Event {
  target: { files: any }
}

const Upload = forwardRef<HTMLInputElement, Props>(function Upload(
  { label = 'Vedlegg', multiple = true },
  ref,
) {
  const [files, setFiles] = useState<any>([])

  const handleChange = (event: Event): void => {
    const rawFiles = Object.values(event.target?.files)
    if (rawFiles && Array.isArray(rawFiles) && rawFiles.length) {
      setFiles(rawFiles)
    }
  }

  return (
    <div {...bem('')}>
      <label {...bem('button')}>
        <input
          type="file"
          ref={ref}
          {...bem('field')}
          multiple={multiple}
          onChange={handleChange}
        />
        <span {...bem('label')}>
          <Icon icon="plus" {...bem('plus')} />
          {label}
        </span>
      </label>

      {files && files.length > 0 && (
        <ul {...bem('files')}>
          {files.map(({ name }) => (
            <li key={name} {...bem('file')}>
              <span {...bem('name')}>{name}</span>
              <button type="button" {...bem('remove')}>
                <Icon icon="remove" />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
})

export default Upload
