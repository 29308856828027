import { useState } from 'react'

export default function useAccordion(
  ref: { current },
  defaultExpanded?: boolean,
  defaultHeight?: string,
): {
  height: string
  expanded: boolean
  doneAnimating: boolean
  expand: () => void
  collapse: () => void
  toggle: () => void
  handleTransitionEnd: () => void
} {
  const [height, setHeight] = useState<string>(defaultHeight || '1000px')
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || false)
  const [doneAnimating, setDoneAnimating] = useState(false)

  const toggle = () => {
    const newState = !expanded
    setExpanded(newState)
    setDoneAnimating(false)

    if (newState) {
      ref?.current?.focus()
      setHeight(`${ref?.current?.scrollHeight}px`)
    }
  }

  const expand = () => {
    setExpanded(true)
    ref?.current?.focus()
    setHeight(`${ref?.current?.scrollHeight}px`)
    setDoneAnimating(false)
  }

  const collapse = () => {
    setExpanded(false)
    setDoneAnimating(false)
  }

  const handleTransitionEnd = () => {
    if (expanded) {
      setDoneAnimating(true)
    }
  }

  return {
    height,
    expanded,
    expand,
    collapse,
    doneAnimating,
    toggle,
    handleTransitionEnd,
  }
}
