import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)

interface Props {
  className?: string
}

const Logo: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="84"
      height="19"
      viewBox="0 0 84 19"
      {...bem('', '', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Faktisk.</title>
      <path
        d="M26.675 0H29.6751V10.6L34.0501 5.25001H37.6251L32.6751 11.025L38.1501 18.15H34.4001L29.6751 11.75V18.15H26.675V0Z"
        {...bem('fill')}
      />
      <path
        d="M40.8751 5.25006V2.05005H43.8751V5.25006H46.4251V7.55006H43.8751V13.6251C43.8751 15.2001 44.2251 15.9501 45.4251 15.9501C45.6751 15.9501 45.9751 15.9501 46.4501 15.8501V18.1501C45.7751 18.3251 45.2001 18.3751 44.5251 18.3751C41.9751 18.3751 40.8751 17.1501 40.8751 14.2251V7.55006H38.8V5.25006H40.8751Z"
        {...bem('fill')}
      />
      <path
        d="M49.1001 0H52.1001V3.02501H49.1001V0ZM49.1001 5.25001H52.1001V18.15H49.1001V5.25001Z"
        {...bem('fill')}
      />
      <path
        d="M57.3001 14.025C57.3001 15.5751 58.2501 16.3501 60.0501 16.3501C61.4751 16.3501 62.4001 15.7501 62.4001 14.75C62.4001 13.475 60.9501 13.125 59.3001 12.725C57.1001 12.2 54.5001 11.625 54.5001 8.80003C54.5001 6.45003 56.6001 4.90002 59.8751 4.90002C63.3001 4.90002 65.2251 6.37503 65.2251 9.20003H62.2501C62.2001 7.67503 61.4001 7.05003 59.7751 7.05003C58.4501 7.05003 57.6001 7.62503 57.6001 8.55003C57.6001 9.95004 59.5001 10.25 61.4251 10.75C63.4501 11.275 65.5001 12.025 65.5001 14.5C65.5001 16.9751 63.3751 18.5001 60.0251 18.5001C56.3751 18.5001 54.2751 16.9501 54.2251 14.025H57.3001Z"
        {...bem('fill')}
      />
      <path
        d="M67.4502 0H70.4502V10.6L74.8252 5.25001H78.4002L73.4502 11.025L78.9252 18.15H75.1752L70.4502 11.75V18.15H67.4502V0Z"
        {...bem('fill')}
      />
      <path d="M80.4502 14.625H83.8502V18.15H80.4502V14.625Z" {...bem('fill')} />
      <path d="M0 8.17505V18.1501H3.17501V10.8001H11.05V8.17505H3.17501H0Z" {...bem('fill')} />
      <path d="M0 0V6.12502H3.17501V2.62501H11.65V0H0Z" {...bem('fill')} />
      <path
        d="M21.4001 16.4751C20.3251 17.7751 19.1001 18.4001 17.0751 18.4001C14.2251 18.4001 12.6001 16.9751 12.6001 14.575C12.6001 10.45 17.7751 10.4 21.0501 10.025V9.47504C21.0501 7.90003 20.2751 7.02503 18.7001 7.02503C17.0751 7.02503 16.1751 7.82503 16.1501 9.20004H13.0751C13.1751 6.50003 15.3501 4.90002 18.7001 4.90002C22.8001 4.90002 23.9751 7.05003 23.9751 10.5V14.1C23.9751 15.5251 24.0251 16.7751 24.2501 18.1501H21.5251C21.4501 17.6501 21.4251 17.1251 21.4001 16.4751ZM21.0501 12.075C18.8001 12.375 15.7001 12.325 15.7001 14.525C15.7001 15.6251 16.4751 16.3251 17.8501 16.3251C19.7001 16.3251 21.0501 15.4251 21.0501 12.625V12.075Z"
        {...bem('fill')}
      />
    </svg>
  )
}

export default Logo
