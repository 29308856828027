import { createClient } from '@sanity/client'
import type { SanityClient } from '@sanity/client'
import { groq } from 'next-sanity'

import imageUrlBuilder from '@sanity/image-url'

export const projectId = process.env.SANITY_PROJECT_ID || 'gjjo24zf'
export const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET || 'production'
export const apiVersion = 'v2021-03-25'

const IS_CI = process.env.CI === '1'

const config = {
  dataset,
  projectId,
  //useCdn: process.env.NODE_ENV === 'production',
  apiVersion,
  useCdn: false,
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
}

const client = createClient(config)
const urlBuilder = imageUrlBuilder(client)

export function getClient(previewToken?: boolean): SanityClient {
  const client = createClient({
    projectId,
    dataset,
    apiVersion: 'v2021-03-25',
    useCdn: true,
  })

  return previewToken
    ? client.withConfig({
        useCdn: false,
        ignoreBrowserTokenWarning: true,
        perspective: 'previewDrafts',
        withCredentials: true,
        token: process.env.SANITY_API_TOKEN,
      })
    : client
}

const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
})

export const getImageUrl = (source: any) => urlBuilder.image(source)

export const slugsOfType = (type: string): Promise<string[]> =>
  client.fetch(
    groq`*[_type == $type && defined(slug.current)]${IS_CI ? '[0..5]' : '[]'}.slug.current`,
    { type },
  )

export async function articleSlugs(): Promise<[string, string][]> {
  const slugs = await client.fetch(
    groq`*[_type == 'article' && defined(slug.current)]${IS_CI ? '[0..5]' : '[]'}.slug`,
  )
  return slugs.map(({ current, pretty }: { current: any; pretty: any }) => [current, pretty])
}

export const aboutUsSlugs = (): ReturnType<typeof slugsOfType> => slugsOfType('aboutUs')

export const topics = async (): Promise<{ type: string; slug: string }[]> => {
  const slugs = await client.fetch(
    groq`*[_type in $topicTypes && defined(slug.current)]${IS_CI ? '[0..5]' : '[]'}{
      "slug": slug.current,
      _type
    }`,
    { topicTypes: ['concept', 'event', 'person', 'organization', 'location'] },
  )

  return slugs.map(({ _type, slug }: any) => ({ type: _type, slug }))
}
