import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import Link from '../Link'
import Icon from '../Icon'

const bem = BEMHelper(styles)

interface Props {
  label?: string
  primary?: boolean
  full?: boolean
  large?: boolean
  center?: boolean
  className?: string
  onClick?: () => void
  href?: string
  target?: string
  rel?: string
  icon?: string
  type?: 'button' | 'submit'
  children?: React.ReactNode
}

const Button: React.FC<Props> = ({
  label,
  primary,
  href,
  full,
  large,
  center,
  onClick,
  className,
  icon,
  type,
  children,
  ...props
}) => {
  const modifiers = { primary, full, large, center, icon: icon && !label }

  if (href) {
    return (
      <Link {...props} href={href} {...bem('', modifiers, className)}>
        <span>
          {label}
          {icon && <Icon icon={icon} />}
        </span>
      </Link>
    )
  }

  return (
    <button type={type || 'button'} {...props} {...bem('', modifiers, className)} onClick={onClick}>
      {label}
      {icon && <Icon icon={icon} />}
      {children && children}
    </button>
  )
}

export default Button
